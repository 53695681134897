import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import Navbar from "Components/NavBar";
import { DataArraySharp } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
// Pilota per API
import configData from "CONFIGURAZIONE.json";
import { useCallback } from "react";
import CardObiettivoSettore from "Components/CardObiettivoSettore";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { createContext, useContext } from "react";
import { LicenzaContext } from "Utils/LicenzaContext";
import columnsReparto from "Utils/Datagrid/Home/Reparto";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import { useMemo } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too
import StarIcon from "@material-ui/icons/Star";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import LoadingSpinner from "Components/LoadingSpinner";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";

import DatePicker from "Components/DatePicker";
import TimePicker from "Components/TimePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import columnsBilanceReparto from "Utils/Datagrid/BilanceReparto/BilanceReparto";
// Filtri
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { fil } from "date-fns/locale";

var server = configData.bilance_api_end_point;
const moment = extendMoment(Moment);

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
  ];
};
// Definizione stile
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  container: {
    padding: theme.spacing(2),
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  chart: {
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "100vw",
      height: "50vh",
    },
  },
  chart2: {
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
      height: "80vh",
    },
  },
}));

function BilanceFasceOrarie() {
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;
  // const configurazioneReport = licenzaContext.licenza.configurazione.Home;
  let history = useHistory();
  // Stile
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));
  // Semafori apertura/chiusura grafici
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // Contenitori dei dati
  const [init, setInit] = useState(false);
  const [jwt, setJWT] = useState("");
  const [reparti, setReparti] = useState([]);
  const [negozi, setNegozi] = useState([]);
  const [filtroReparti, setFiltroReparti] = useState([]);
  ////////////////////////////////////////////////////
  // FILTRI

  // Negozio
  const [filtroNegozi, setFiltroNegozi] = useState([]);

  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);

  // Intervallo date
  const [filtroDataDa, setFiltroDataDa] = useState(
    moment(new Date()).subtract(1, "days")
  );

  const handleFiltroDataDaChange = (value) => {
    // if string cast to moment
    setFiltroDataDa(moment(value));
  };

  // Ora Da
  const [filtroOraDa, setFiltroOraDa] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );

  const handleFiltroOraDaChange = (value) => {

    setFiltroOraDa(moment(value));
  };

  // Data A
  const [filtroDataA, setFiltroDataA] = useState(
    moment(new Date()).subtract(1, "days")
  );
  const handleFiltroDataAChange = (value) => {
    setFiltroDataA(moment(value));
  };

  // Ora Da
  const [filtroOraA, setFiltroOraA] = useState(
    new Date(new Date().setHours(23, 59))
  );
  const handleFiltroOraAChange = (value) => {
    setFiltroOraA(moment(value));
  };
  ////////////////////////////////////////////////////

  const [idNegozi, setIdNegozi] = useState([]);

  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          negozi.push({
            chiave: negozio.id_negozio,
            valore: negozio.nome_negozio,
          });
        });
        setIdNegozi(negozi);
        setFiltroNegozi([negozi[0].id_negozio]);
      }
    });
  }, []);

  

  useEffect(() => {
    axios.get(`${server}/${idCliente}/reparti`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        let reparti = []
        console.log(response.data);
        response.data.forEach((reparto) => {
          reparti.push({ "chiave": reparto.idDepartment, "valore": reparto.nameDepartment });
        });
        setReparti(reparti)
        setFiltroReparti('Tutti');
      }
    });
  }, []);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
    axios.get(`${server}/jwt`).then((response) => {
      if (response.status == 200) {
        console.log(response.data);

        setJWT(response.data.token)
      }
    });
  }, []);


  

  const applyVizFilter = () => {
    const vizElement = document.getElementById("tableauViz");
    if (!vizElement?.workbook?.activeSheet) return;

    const sheet = vizElement.workbook.activeSheet;
    try {
      // You must pass an array of values for the filter
      // "replace" => remove existing filter, use new one
      // From reparti pick the id in filtroReparti
      let _reparti_viz = []
      let _negozi_viz = []
      reparti.forEach((reparto) => { if (filtroReparti.includes(reparto.chiave)) _reparti_viz.push(reparto.valore) });
      idNegozi.forEach((negozio) => {if (filtroNegozi.includes(negozio.chiave)) _negozi_viz.push(negozio.chiave)})
      console.log(sheet);
      //check type of filtroDataDa and filtroDataA 

      console.log(filtroDataA);
      // From moment object to Date object
      const minDate = Date.UTC(filtroDataDa.year(), filtroDataDa.month(), filtroDataDa.date(), filtroOraDa.getHours(), filtroOraDa.getMinutes());
      const maxDate = Date.UTC(filtroDataA.year(), filtroDataA.month(), filtroDataA.date(), filtroOraA.getHours(), filtroOraA.getMinutes());
      //cast from moment to DATE
      
      sheet.worksheets[0].applyRangeFilterAsync("Date", {
            min: new Date(minDate),
            max: new Date(maxDate)
      });
      sheet.worksheets[0].applyFilterAsync("Name Department", _reparti_viz, "replace");
      sheet.worksheets[0].applyFilterAsync("Id Negozio1", _negozi_viz, "replace");

      console.log(`Applied store filter ${_negozi_viz}`);
      console.log(`Applied department filter: ${_reparti_viz}`);
      console.log(`Applied date filter: ${minDate} - ${maxDate}`);
    } catch (err) {
      console.error("Error applying store filter:", err);
    }
  };



  // Render condizionale
  const BilanceFasceOrarieRendered = () => {
    if (desktop | tablet)
      if (init) {
        return (
          <div style={{ width: "100%", height: "80vh" }}>
            <tableau-viz id="tableauViz"
              
              src='https://eu-west-1a.online.tableau.com/t/sistemiposwebdev/views/TestBilData/MainDashAll'
              token={jwt}>
              <viz-filter field="Negozio" value="['0101']"> </viz-filter>  
            </tableau-viz>
          </div>
        );
      }

  };

  // Render della pagina
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.root}>
        <Navbar
          idCliente={idCliente}
          openVendite={true}
          openFasceOrarie={true}
          breadcrumbsReference={BreadcrumbsReference(idCliente)}
        />
        <Grid container className={classes.container} spacing={2} direction={"row"}>
          <Grid item xl={12} md={12}>
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={5} md={5} direction="row">
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid
                          container
                          item
                          xl={12}
                          md={12}
                          spacing={1}
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid item xl={2} md={2}>
                            Da:
                          </Grid>
                          <Grid item xl={6} md={6}>
                            <DatePicker
                              data={filtroDataDa}
                              handleOnChange={handleFiltroDataDaChange}
                            />
                          </Grid>
                          <Grid item xl={4} md={4}>
                            <TimePicker
                              time={filtroOraDa}
                              handleOnChange={handleFiltroOraDaChange}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          xl={12}
                          md={12}
                          spacing={1}
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid item xl={2} md={2}>
                            A:
                          </Grid>
                          <Grid item xl={6} md={6}>
                            <DatePicker
                              data={filtroDataA}
                              handleOnChange={handleFiltroDataAChange}
                            />
                          </Grid>
                          <Grid item xl={4} md={4}>
                            <TimePicker
                              time={filtroOraA}
                              handleOnChange={handleFiltroOraAChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xl={7} md={7}>
                      <Grid item xl={6} md={6}>
                        <MultipleSelectCheckmarks
                          label={"Selezione Negozi"}
                          selezioneMultipla={true}
                          items={idNegozi}
                          setState={setFiltroNegozi}
                        />
                      </Grid>
                      <Grid item xl={6} md={6}>
                        <MultipleSelectCheckmarks
                          label={"Selezione Reparti"}
                          selezioneMultipla={true}
                          items={reparti}
                          setState={setFiltroReparti}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            applyVizFilter();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Visualizza
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xl={12} md={12} >
            {
              BilanceFasceOrarieRendered()
            }
          </Grid>
        </Grid>




      </div>
    </main>
  );
}

export default BilanceFasceOrarie;
